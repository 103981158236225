import React, { useContext } from 'react'
import { NotificationManagerContext, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CheckmarkIcon, LockGlyph12Icon, LockGlyph24Icon } from 'assets/icons'
import privacyConstants from 'config/constants/initiativePrivacy'
import { initiativeSettingsPrivacyPermissionsUrl } from 'config/constants/urls'
import statusThemes from 'config/constants/statusThemes'
import { useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import LiveEventBanner from 'src/sites/custom/banff-pipeline-workshop/LiveEventBanner'

const ObjectStatusBanner = ({
   editUrl,
   textAlign,
   textSize
}) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative, mutating } = useUpdateInitiative()
   const parentInitiative = initiative.ancestors[0]

   const handlePublishInitiative = async () => {
      try {
         await updateInitiative({ id: initiative.id }, { privacy: 'inherit' })

         showNotification({
            message: `${initiative.type.nameSingular} is now published!`,
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const bannerProps = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '15px 20px',
      textAlign
   }

   if (mutating) {
      return (
         <View {...bannerProps} backgroundColor="rgba(170, 217, 227, 0.2)">
            <Spinner width="16px" height="16px" color="rgb(154, 206, 217)" />
         </View>
      )
   }

   // If discussion and is closed (answered or thread is closed)
   if (initiative.discussionStatus?.closed) {
      return (
         <View {...bannerProps} backgroundColor="rgba(72, 193, 146, 0.1)"
            textAlign={textAlign}>
            <Text size={textSize} lineHeight="140%" color="rgba(72, 193, 146, 1)">
               <View display="inline-block" marginRight="20px">
                  {initiative.discussionFormat === 'discussion' && (
                     <LockGlyph24Icon width="16px" height="16px" color="rgba(72, 193, 146, 1)" />
                  )}
                  {initiative.discussionFormat === 'qa' && (
                     <CheckmarkIcon width="16px" height="16px" color="rgba(72, 193, 146, 1)" />
                  )}
               </View>
               {initiative.discussionFormat === 'qa'
                  ? 'This question has been answered'
                  : 'This discussion thread is closed'
               }
            </Text>
         </View>
      )
   }

   if (initiative.archived) {
      return (
         <View {...bannerProps} backgroundColor={statusThemes.archived.backgroundColor}>
            <Text size={textSize} lineHeight="140%" color="#524439">
               {`This ${initiative.type.nameSingular} is Archived.`}
            </Text>
         </View>
      )
   }

   // This is subintiatives that are Events
   // If initiative is an event with a privacy of inherit,
   // we want to use the more restrictive privacy between the event and its parent (if it has one)
   if (parentInitiative &&
      initiative.class === 'event' &&
      initiative.privacy === 'inherit' &&
      ['private', 'admin', 'draft'].includes(parentInitiative.privacy)) {
      const parentPrivacyConfig = privacyConstants.find(p => p.value === parentInitiative.privacy)

      return (
         <View {...bannerProps} backgroundColor={parentPrivacyConfig.backgroundColor}>
            <Text size={textSize} lineHeight="140%" color="#524439">
               {parentInitiative.privacy === 'private' && (
                  <View display="inline-block" marginRight="10px">
                     <LockGlyph12Icon width="10px" height="10px" color="#524439" />
                  </View>
               )}
               {/* PRIVATE PRIVACY */}
               {parentInitiative.privacy === 'private' && (
                  <>
                     {`This ${initiative.type.nameSingular} belongs to a ${parentInitiative.type.nameSingular} that is Private.`}
                     <br />
                     {`Only people that are part of the ${parentInitiative.name} ${parentInitiative.type.nameSingular} can see this ${initiative.type.nameSingular}.`}
                  </>
               )}
               {/* ADMIN PRIVACY */}
               {parentInitiative.privacy === 'admin' && (
                  `This ${initiative.type.nameSingular} belongs to a ${parentInitiative.type.nameSingular} that is currently in Admin-only Mode.`
               )}
               {/* DRAFT PRIVACY */}
               {parentInitiative.privacy === 'draft' && (
                  `This ${initiative.type.nameSingular} belongs to a ${parentInitiative.type.nameSingular} that is currently in Draft Mode. It is only visible to you.`
               )}
               &nbsp;
               {parentInitiative.currentUserCanEdit && (
                  <Link to={editUrl || initiativeSettingsPrivacyPermissionsUrl(parentInitiative)}>
                     <u style={{ color: '#524439' }}>
                        <b>Update</b>
                     </u>
                  </Link>
               )}
            </Text>
         </View>
      )
   }

   if (organization.slug === 'banff-pipeline-workshop' && initiative.privacy === 'inherit' && initiative.class !== 'event' && parentInitiative?.class !== 'event') {
      return (
         <LiveEventBanner />
      )
   }
   if (initiative.privacy === 'inherit') {
      return null
   }

   const currentPrivacyOption = privacyConstants.find(p => p.value === initiative.privacy)

   // For all Pages (root and pages with a parent initiative)
   // TODO: These descriptions should be moved to initaitivePrivacy constants PR #994
   if (initiative.class === 'page') {
      return (
         <View {...bannerProps} backgroundColor={currentPrivacyOption.backgroundColor}>
            <Text size={textSize} lineHeight="140%" color="#524439">
               {initiative.privacy === 'private' && (
                  <View display="inline-block" marginRight="10px">
                     <LockGlyph12Icon width="10px" height="10px" color="#524439" />
                  </View>
               )}
               {/* PRIVATE PRIVACY */}
               {initiative.privacy === 'private' && (
                  `This ${initiative.type.nameSingular} is Private. Only members of the ${organization.name} Community can see this ${initiative.type.nameSingular}.`
               )}
               {initiative.privacy === 'admin' && (
                  <>
                     {`This ${initiative.type.nameSingular} `}
                     <b>{initiative.name}</b>
                     {' is in Admin-only Mode'}
                  </>
               )}
               {initiative.privacy === 'draft' && (
                  `This ${initiative.type.nameSingular} is currently a Draft and is only visible to you.`
               )}
               {['admin', 'draft'].includes(initiative.privacy) && (
                  <View
                     onClick={handlePublishInitiative}
                     marginLeft="8px"
                     display="inline-block">
                     <u style={{ color: '#524439', cursor: 'pointer' }}>
                        <b>Publish now</b>
                     </u>
                  </View>
               )}
            </Text>
         </View>
      )
   }

   return (
      <View {...bannerProps} backgroundColor={currentPrivacyOption.backgroundColor}>
         <Text size={textSize} lineHeight="140%" color="#524439">
            {/* PRIVATE PRIVACY */}
            {initiative.privacy === 'private' && (
               `This ${initiative.type.nameSingular} is Private. Only people that are invited can see and join this ${initiative.type.nameSingular}.`
            )}
            {/* ADMIN PRIVACY */}
            {initiative.privacy === 'admin' && (
               `This ${initiative.type.nameSingular} is currently in Admin-only Mode. It is only visible to admins of this ${initiative.type.nameSingular} and the ${organization.name} Community.`
            )}
            {/* DRAFT PRIVACY */}
            {initiative.privacy === 'draft' && (
               `This ${initiative.type.nameSingular} is currently a Draft and is only visible to you.`
            )}
            &nbsp;
            {initiative.currentUserCanEdit && (
               <Link to={editUrl || initiativeSettingsPrivacyPermissionsUrl(initiative)}>
                  <u style={{ color: '#524439' }}>
                     <b>Update</b>
                  </u>
               </Link>
            )}
         </Text>
      </View>
   )
}

ObjectStatusBanner.propTypes = {
   editUrl: PropTypes.string,
   textAlign: PropTypes.oneOf(['left', 'center', 'right']),
   textSize: PropTypes.string
}

ObjectStatusBanner.defaultProps = {
   editUrl: undefined,
   textAlign: 'center',
   textSize: '1.5[a-d] 2[e-f]'
}

export default ObjectStatusBanner

// =========================================================================================@@
// Last Updated Date: Apr 7, 2025
// Last Updated By: Steven
// Status Level: 2
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Carousel, ListMenu, ListMenuButton, Modal, Text, View } from 'oio-react'
import { NavLink, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { toast } from 'sonner'
import { MoreIcon } from 'assets/icons'
import initiativePrivacyConstants from 'config/constants/initiativePrivacy'
import { useInitiativeList, useMe, useOrganization, useUpdateInitiative,
   useUpdateRelationship } from 'src/core/graphql/hooks'
import InitiativeAppMoreControl from 'src/sites/kits/Initiative/components/AppMoreControl'
import ObjectDeleteConfirm from 'src/sites/kits/Object/components/DeleteConfirm'
import { EmptyContentBlock } from 'src/sites/kits/UI'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import Popover from 'src/sites/kits/Utils/Popover'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import ScheduleFilterControlModal from '~/components/ScheduleFilterControl'
import SessionListItem from '~/components/SessionListItem'
import SessionCreate from './SessionCreate'
import Session from './Session'

const adminOnlyPrivacyConstants = initiativePrivacyConstants.find(p => p.value === 'admin')

const EventSchedule = () => {
   const history = useHistory()
   const match = useRouteMatch()
   const { selectedDateKey: initialSelectedDateKey } = useParams()
   const [initiativeForDeletion, setInitiativeForDeletion] = useState(null)
   let emptyContentMessage = 'No sessions to show for this date'

   // This is handled by ScheduleFilterControl via session storage
   const [scheduleFilter, setScheduleFilter] = useState()
   const showBookmarksOnly = scheduleFilter?.type === 'bookmarks'

   let startDateAfter
   let startDateBefore
   if (initialSelectedDateKey) {
      const initialSelectedDateObj = moment(initialSelectedDateKey, 'YYYY[-]MM[-]DD')
      startDateAfter = initialSelectedDateObj.startOf('day').toDate()
      startDateBefore = initialSelectedDateObj.endOf('day').toDate()
   }

   const { me, isLoggedIn } = useMe()
   const { organization } = useOrganization()
   const sessionInitiativeType = organization.initiativeTypes.find(t => t.class === 'session')
   const { initiative: event, refetch } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { updateRelationship } = useUpdateRelationship()

   const sessionListFilter = {
      archived: 'exclude',
      drafts: 'include',
      limit: 125,
      parentInitiativeId: event.id,
      sortBy: 'startDateAsc',
      timezoneOffset: new Date().getTimezoneOffset(),
      typeIds: [sessionInitiativeType.id],
      withUserHasFavorited: true,
      startDateAfter,
      startDateBefore
   }

   const {
      initiativeList: sessionList,
      refetch: refetchSessionList,
      networkStatus
   } = useInitiativeList(sessionListFilter)

   const baseUrl = initialSelectedDateKey
      ? match.url.split('/').slice(0, -1).join('/')
      : match.url

   let selectedDateKey = initialSelectedDateKey
   if (!selectedDateKey) {
      const now = moment().format('YYYY-MM-DD')
      if (event.eventSessionDays.includes(now)) {
         selectedDateKey = now
      } else {
         selectedDateKey = event.eventSessionDays?.[0]
      }
   }

   let filteredSessions = sessionList.items

   if (scheduleFilter?.labelId) {
      const activeLabelObj = event.appsScheduleLabels.find(l => l.id === scheduleFilter?.labelId)

      filteredSessions = filteredSessions.filter(session => (
         session.labels.some(label => label.id === scheduleFilter?.labelId)
      ))

      if (filteredSessions.length === 0) {
         emptyContentMessage = `No sessions to show for ${activeLabelObj.name}`
      }
   }

   if (showBookmarksOnly) {
      filteredSessions = filteredSessions.filter(session => (
         session.currentUserRelationship?.isFavorited
      ))

      if (filteredSessions.length === 0) {
         emptyContentMessage = 'You have no bookmarked sessions for this date'
      }
   }

   // Group Sessions by Time
   const sessionsByStartTimeGrouping = filteredSessions.reduce((result, initiative) => {
      const existingStartTimeGrouping = result.find(r => r.startTime === initiative.startTime)

      if (!existingStartTimeGrouping) {
         return [...result, { startTime: initiative.startTime, sessions: [initiative] }]
      }

      existingStartTimeGrouping.sessions.push(initiative) // TODO: What is this doing?
      return result
   }, [])

   const handleToggleSchedulePublished = async () => {
      await updateInitiative({ id: event.id }, {
         appsSchedulePublished: !event.appsSchedulePublished
      })

      refetch()
   }

   const handleToggleSessionBookmarked = async (sessionId, isFavorited) => {
      if (!isLoggedIn) {
         return toast('You must be logged in to add a Bookmark')
      }

      try {
         await updateRelationship({
            entityType: 'initiative',
            entityId: sessionId,
            userId: me.id,
            elements: [{
               operation: 'favorite',
               favorite: !isFavorited
            }]
         })

         if (isFavorited) {
            toast('Removed from Bookmarks')
         } else {
            toast.success('Added to Bookmarks')
         }
      } catch (err) {
         toast.error('There was an error', {
            description: err.message
         })
      } finally {
         refetchSessionList()
      }
   }

   useEffect(() => {
      setTimeout(() => {
         const objectNav = document.getElementById('gather-object-nav')

         if (objectNav) {
            window.scrollTo({
               top: objectNav.offsetTop,
               behavior: 'smooth'
            })
         }
      }, 0)
   }, [initialSelectedDateKey])

   // Redirect to first date in the schedule
   if (!initialSelectedDateKey && selectedDateKey && networkStatus === 7) {
      history.replace(`${baseUrl}/${selectedDateKey}`)
      return null
   }

   return (
      <>
         {!event.appsSchedulePublished && (
            <View
               width="100%"
               padding="15px 20px"
               backgroundColor={adminOnlyPrivacyConstants.backgroundColor}
               textAlign="center">
               <Text size="1.5" color="#524439">
                  This Schedule page is currently in Admin-only Mode
                  <View
                     onClick={handleToggleSchedulePublished}
                     marginLeft="10px"
                     display="inline-block">
                     <u style={{ color: '#524439', cursor: 'pointer' }}>
                        <b>Publish now</b>
                     </u>
                  </View>
               </Text>
            </View>
         )}
         <View
            position="sticky"
            top="0px"
            backgroundColor="var(--bodyBackgroundColor)"
            borderBottom="1px solid var(--primaryLineColor)[c-f]"
            display="flex"
            flexFlow="column[a-b] row[c-f]"
            alignItems="center[c-f]"
            height="80px[d-f]"
            zIndex="var(--scheduleDatesHeaderZindex)">
            <View
               width="100%[a-b] 70%[c-f]"
               flex="0 0 auto"
               height="64px[a-b]"
               display="flex[a-b]"
               alignItems="flex-end"
               paddingRight="16px"
               paddingTop="4px[a-b]"
               backgroundColor="var(--bodyBackgroundColor)">
               {/* TODO: For release, make this work even if your event is crazy long (+6 days) */}
               {event.eventSessionDays.length > 0 && (
                  <Carousel
                     buttonOffsetFromSides="5px"
                     numSlidesPerPane="6[a] 9[b] 8[c-f]"
                     spacing={8}>
                     {event.eventSessionDays.map((dayKey) => {
                        const dateObj = moment(dayKey, 'YYYY[-]MM[-]DD')
                        const dayOfWeek = dateObj.format('ddd')
                        const dateOfMonth = dateObj.format('D')

                        return (
                           <NavLink
                              key={dayKey}
                              to={`${baseUrl}/${dayKey}`}
                              className="gather-schedule-date-button"
                              activeClassName="active">
                              <View
                                 className="inner"
                                 position="absolute"
                                 top="0px"
                                 left="0px"
                                 right="0px"
                                 bottom="0px">
                                 <div className="dayOfWeek">{dayOfWeek}</div>
                                 <div className="dateOfMonth">{dateOfMonth}</div>
                              </View>
                           </NavLink>
                        )
                     })}
                  </Carousel>
               )}
            </View>
            <View
               display="flex"
               alignItems="center"
               justifyContent="flex-end"
               width="100%[a-b] 30%[c-f]"
               height="48px[a-b] 100%[c-f]"
               backgroundColor="var(--bodyBackgroundColor)">
               <ScheduleFilterControlModal onChange={setScheduleFilter} />
               <InitiativeAppMoreControl
                  appName="Schedule"
                  appEnabledFieldName="appsScheduleEnabled"
                  appPublishedFieldName="appsSchedulePublished"
               />
            </View>
         </View>
         <View width="100%">
            {filteredSessions.length === 0 && (
               <EmptyContentBlock
                  message={emptyContentMessage}
               />
            )}
            <Popover.Provider>
               {sessionsByStartTimeGrouping.map(grouping => (
                  <View
                     key={grouping.startTime}
                     position="relative"
                     width="100%"
                     borderBottom="1px solid var(--primaryLineColor)[c-f]"
                     paddingLeft="160px[c-f]">
                     <View
                        position="sticky"
                        top="110px[a-b] 72px[c-f]"
                        left="0px"
                        width="100%[a-b] 160px[c-f]"
                        flex="0 0 auto[c-f]"
                        backgroundColor="var(--stickyHeaderBackgroundColor)[a-b]"
                        borderRadius="4px[a-b]"
                        padding="8px[a-b]"
                        zIndex="var(--scheduleTimeHeaderZindex)">
                        <View
                           position="absolute[c-f]"
                           left="-160px[c-f]"
                           width="160px[c-f]"
                           backgroundColor="white[c-f]"
                           padding="18px 0px[c-f]">
                           <div className="ui-meta-text">
                              <b>{grouping.startTime}</b>
                           </div>
                        </View>
                     </View>
                     {grouping.sessions.map((session, index) => (
                        <SessionListItem
                           key={session.id}
                           borderStyle={index === 0 ? 'none' : undefined}
                           endTime={session.endTime}
                           isFavorited={session.currentUserRelationship?.isFavorited}
                           labels={session.labels}
                           location={session.location?.name}
                           linkTo={`${baseUrl}/${selectedDateKey}/session/${session.slug}`}
                           name={session.name}
                           startTime={session.startTime}
                           timezone={session.startDateTz}
                           onBookmarkButtonClick={() => {
                              handleToggleSessionBookmarked(
                                 session.id,
                                 session.currentUserRelationship?.isFavorited
                              )
                           }}
                           action={event.currentUserCanEdit && (
                              <Popover.Anchor meta={session} tabIndex={index}>
                                 <MoreIcon
                                    width="24px"
                                    height="24px"
                                    color="#aaa"
                                 />
                              </Popover.Anchor>
                           )}
                        />
                     ))}
                  </View>
               ))}
               <Popover.View
                  anchorOriginHorizontal="right"
                  anchorOriginVertical="top"
                  backgroundColor="#fff"
                  borderRadius="3px"
                  width="150px"
                  margin="10px">
                  {session => (
                     <View
                        float="left"
                        width="100%"
                        backgroundColor="#fff"
                        borderRadius="3px"
                        boxShadow="3px 3px 5px rgba(0,0,0,0.1)">
                        <ListMenu buttonSize="sm" buttonTextColor="#333">
                           <ListMenuButton
                              linkTo={`${baseUrl}/${selectedDateKey}/session/${session.slug}/-/settings`}
                              name="Edit Session"
                           />
                           <ListMenuButton
                              onClick={() => setInitiativeForDeletion({
                                 // eslint-disable-next-line max-len
                                 activeSubInitiativeTypeNames: session.activeSubInitiativeTypeNames,
                                 id: session.id,
                                 name: session.name
                              })}
                              name="Delete Session"
                           />
                        </ListMenu>
                     </View>
                  )}
               </Popover.View>
            </Popover.Provider>
         </View>
         <Switch>
            <ModalRoute path={`${baseUrl}/${selectedDateKey}/create`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="5px"
                     width="270px"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <SessionCreate
                        initiativeTypeId={sessionInitiativeType.id}
                        onCancelButtonClick={onCloseTrigger}
                        onCreate={onCloseTrigger}
                        parentInitiativeId={event.id}
                     />
                  </Modal>
               )}
            </ModalRoute>
            <ModalRoute path={`${baseUrl}/${selectedDateKey}/session/:sessionSlug`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Session
                     modalOnCloseComplete={onCloseComplete}
                     modalOnCloseTrigger={onCloseTrigger}
                     modalOpen={open}
                  />
               )}
            </ModalRoute>
         </Switch>
         {/* Delete Initiative Confirmation Modal */}
         {initiativeForDeletion && (
            <Modal
               borderRadius="5px"
               width="90%[a-b] 300px[c-f]"
               onCloseTrigger={() => setInitiativeForDeletion(null)}
               open={initiativeForDeletion}>
               <ObjectDeleteConfirm
                  activeSubInitiativeTypeNames={initiativeForDeletion.activeSubInitiativeTypeNames}
                  initiativeId={initiativeForDeletion.id}
                  initiativeName={initiativeForDeletion.name}
                  onCancelButtonClick={() => setInitiativeForDeletion(null)}
                  onDelete={() => setInitiativeForDeletion(null)}
               />
            </Modal>
         )}
      </>
   )
}

export default EventSchedule

// =========================================================================================@@
// Last Updated Date: Apr 7, 2025
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useState } from 'react'
import { Button } from 'oio-react'
import { useOrganization } from 'src/core/graphql/hooks'
import BPWHomeVideo from 'src/sites/custom/banff-pipeline-workshop/HomeVideo'
import RichText from 'src/sites/kits/Utils/RichText'
import BPWSponsorsBlock from 'src/sites/custom/banff-pipeline-workshop/SponsorsBlock'
import LiveEventBanner from '../LiveEventBanner'

const HomeLayoutSidebar = () => {
   const [modalOpen, setModalOpen] = useState(false)
   const { organization } = useOrganization()
   const { highlightColor: buttonColor } = organization.theme

   return (
      <div className="w-full min-h-screen flex flex-col justify-center">
         <LiveEventBanner />
         <div
            className="block 2xl:hidden w-full aspect-[16/9] md:aspect-[16/5] 2xl:aspect-auto 2xl:h-full bg-cover bg-center bg-no-repeat bg-black"
            style={{
               backgroundImage: `url(${organization.coverMedia?.file.thumbnailUrlW1600})`
            }}
         />
         <div className="w-full max-w-screen-md xl:max-w-[900px] 2xl:max-w-none mx-auto py-8 px-6 md:px-16 sm:py-12 lg:py-16 xl:py-8 xl:px-12 2xl:py-0 2xl:px-0">
            <div className="flex flex-col 2xl:flex-row">
               <div className="2xl:w-[760px] 2xl:flex-none pb-8 xl:py-12 xl:pt-12 2xl:px-14 2xl:pt-20 2xl:pb-32">
                  <div className="pb-4 text-base font-bold opacity-50">
                     Join us April 7-10, 2025!
                  </div>
                  <div className="xl:max-w-[800px] text-4xl md:text-5xl xl:text-6xl font-[650] md:font-[600] tracking-tighter xl:tracking-tightest">
                     {organization.tagline}
                  </div>
                  <div className="ui-paragraph-text pt-6 pb-8">
                     <RichText float="none" html={organization.description} />
                  </div>
                  <Button
                     onClick={() => setModalOpen(true)}
                     name="Watch Video"
                     color={buttonColor}
                     size="xl"
                     rounded
                  />
               </div>
               <div className="hidden 2xl:block w-full 2xl:flex-0">
                  <div
                     className="w-full h-full bg-cover bg-center bg-no-repeat bg-black"
                     style={{
                        backgroundImage: `url(${organization.coverMedia?.file.thumbnailUrlW1600})`
                     }}
                  />
               </div>
            </div>
            <div className="2xl:max-w-[1400px] 2xl:mx-auto py-8 2xl:px-16">
               <BPWSponsorsBlock
                  filterTag="banner"
                  gridClassName="grid-cols-3 sm:grid-cols-4 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-8 md:gap-12"
                  initiativeSlug="home"
                  title="Banner Sponsor"
                  titlePaddingClassName="py-6"
               />
               <BPWSponsorsBlock
                  filterTag="featured"
                  gridClassName="grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-6"
                  initiativeSlug="home"
                  title="Featured Sponsors"
                  titlePaddingClassName="py-6"
               />
            </div>
         </div>
         <BPWHomeVideo
            modalOnCloseComplete={() => setModalOpen(false)}
            modalOnCloseTrigger={() => setModalOpen(false)}
            modalOpen={modalOpen}
         />
      </div>
   )
}

export default HomeLayoutSidebar
